<template>
    <div class="TheFoxDen Wrapper-section" id="TheFoxDenPage">
        <div class="Trigger TriggerTop" id="TheFoxDenTriggerTop"></div>
        <div class="TheFoxDenIntro">
            <div class="TheFoxDenIntro-container" id="TheFoxDen">
                <h2 class="title TheFoxDenIntro-title">Find a Fox Den</h2>
                <h3 class="subtitle TheFoxDenIntro-subtitle">Free from the constraints of the traditional ways of working, we're a fully digital team, effortlessly connecting across three different continents and four time zones, making magic happen every hour of everyday.</h3>
                <p><a :href="`mailto:${headquarter.email}`" class="TheFoxDenIntro-cta">{{headquarter.email}}</a></p>
            </div>
            <div :style="`background-image: url('${this.publicPath}img/theFoxDenBg.jpg')`" class="TheFoxDenIntro-bg"></div>
        </div>
        <div class="TheFoxDenCountries">
            <div class="TheFoxDenCountries-container">
                <section
                        :class="`TheFoxDenCountry TheFoxDenCountry-${office.city}`"
                        :key="index"
                        v-for="(office,index) in offices"
                >
                    <h2 class="TheFoxDenCountry-city">{{office.city}}</h2>
                    <p class="TheFoxDenCountry-country">{{office.country}}</p>
                    <p class="TheFoxDenCountry-address" v-html="office.address" v-if="office.address"></p>
                    <p class="TheFoxDenCountry-email" v-if="office.email && !office.headquarter">
                        <a :href="`mailto:${office.email}`">{{office.email}}</a>
                    </p>
                </section>
            </div>
        </div>
        <div class="Trigger TriggerBottom" id="TheFoxDenTriggerBottom"></div>
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'TheFoxDen',
        data() {
            return {
                publicPath: process.env.BASE_URL,
                offices: store.state.offices,
                sceneTimer: null,
                scrollMagicTrigger: ['TheFoxDenTriggerTop'],
                scrollMagicSectionId: ['TheFoxDenPage']
            };
        },
        computed: {
            headquarter() {
                return this.offices.filter(office => office.headquarter)[0];
            }
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const SMC = new this.$scrollmagic.Controller();

                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicTrigger[i]}`,
                        triggerHook: 'onEnter'
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }
            }
        }
    };
</script>
